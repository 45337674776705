//==============================
// Hero Slider
//==============================

var heroSlider = $('.hero-slider');

heroSlider.slick({
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 800,
  slidesToShow: 1,
  swipeToSlide: true,
  dots: true,
  arrows: true
});
