//==============================
// Header
//==============================

var header = $('.header');
var button = $('.header-nav-button');
var nav = $('.header-nav');
var main = $('.main');

// button
button.click(function() {
  header.toggleClass('open');
  button.toggleClass('open');
  nav.toggleClass('open');
});

main.click(function() {
  header.removeClass('open');
  button.removeClass('open');
  nav.removeClass('open');
});