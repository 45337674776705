//==============================
// Gallery Slider
//==============================

var heroSlider = $('.gallery-slider');

heroSlider.slick({
  autoplay: false,
  speed: 600,
  slidesToShow: 1,
  swipeToSlide: true,
  dots: true,
  arrows: true,
  centerMode: true,
  centerPadding: '10%',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        centerPadding: '5%'
      }
    }
  ]
});
