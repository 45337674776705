//==============================
// Art Gallery
//==============================

var artGallery = $('.art-gallery').masonry({
  itemSelector: '.art-gallery-item',
  percentPosition: true,
  gutter: 10
});

artGallery.imagesLoaded().progress( function() {
  artGallery.masonry('layout');
  setTimeout(function() {
    $('.art-gallery-item').addClass('show');
  }, 500);
});
